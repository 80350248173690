import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import momentPlugin from '@fullcalendar/moment'

import { Calendar } from "@fullcalendar/core";
import { Draggable } from "@fullcalendar/interaction";

import fr from "@fullcalendar/core/locales/fr";
import { ref } from "@vue/reactivity";
import { computed, watch } from "@vue/runtime-core";
import { useStore } from 'vuex'

import engine from "../core/index";
import moment from 'moment';
import timeGridPlugin from '@fullcalendar/timegrid';
import { onMounted } from "vue";
import listPlugin from '@fullcalendar/list';

export default function calendarData(handleEventClick,
    handleEventDrop,
    handleEventDragTime,
    handleEventReceive,
    getTechToShow,
    setAgency,
    getAgency,
    fullView
) {
    const store = useStore()
    const eventColorById = ref({
        '100': { label: 'En attente', color: '#607D8B' },
        '110': { label: 'Planifié', color: '#FFA726' },
        '120': { label: 'Envoyer vers tablette', color: 'purple' },
        '130': { label: 'Récupéré', color: '#3F51B5' },
        '140': { label: 'Terminé', color: '#43A047' }
    })
    const daysToHide = ref([]);
    const isDataLoaded = ref(false)
    const isModalEventOpen = ref(false)
    const isModalSettingsOpen = ref(false)
    const selectedEvent = ref({});
    const eventsCalendar = ref([])
    const technicienList = ref([])
    const eventsCalendarOld = ref([])
    const view = ref({ start: moment().format('MM/DD/YYYY'), end: moment().format('MM/DD/YYYY') })
    const eventsList = ref([]);
    const isConnected = ref(true);
    const calendarDate = ref(moment().format('YYYY-MM-DD'))
    const selectedAgency = ref(undefined);
    const agencyList = ref(undefined);
    const selectedTechniciens = ref([]);
    const technicienListAvailable = ref([])
    const commentaireListe = ref([])
    const errorMessage = ref(undefined);
    const planningSettings = ref({});

    const calendarOptions = computed(function() {
        let option = {
            schedulerLicenseKey: '0359429366-fcs-1650894843',
            datesSet: updateCalendarView,
            nowIndicator: true,
            initialDate: calendarDate.value,
            plugins: [momentPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimelinePlugin, listPlugin],
            // initialView: "resourceTimelineWeek",
            resourceAreaWidth: "180px",
            height: fullView.value ? "93vh" : '92.5vh',
            locale: fr,
            events: getFilteredEvents.value,
            eventClick: handleEventClick,
            eventDrop: handleEventDrop,
            eventResize: handleEventDragTime,
            hiddenDays: daysToHide.value,
            eventReceive: handleEventReceive,
            resourceAreaHeaderContent: 'Techniciens',
            resources: getFilteredTechnicien.value,
            slotMinTime: "06:00:00",
            slotMaxTime: "19:00:00",
            titleFormat: { month: 'short', day: 'numeric' },
            // views: {
            //     day: {
            //         titleFormat: 'dddd D MMMM YYYY',
            //     },
            // },
            headerToolbar: {
                left: 'prev today',
                center: 'title',
                right: 'next'
            },
            allDaySlot: false,
            // slotDuration: "02:00:00",
            editable: isRoot.value,
            droppable: true,
            buttonText: {
                // next: '>',
                // nextYear: '>>',
                // prev: '<',
                // prevYear: '<<',
                today: 'AUJ'
            },
        };

        if (fullView.value) {
            option['customButtons'] = {
                parameter: {
                    text: 'Paramètre',
                    click: openParametrage
                }
            }
            console.log("isRoot", isRoot.value);
            option.headerToolbar['left'] = isRoot.value == true ? 'today parameter' : 'today'
            option.headerToolbar['right'] = 'resourceTimelineDay,resourceTimelineWeek prev,next'
        }

        return option;
    });

    const openParametrage = function() {
        isModalSettingsOpen.value = true
    }

    const updateCalendarDaysToShow = function() {
        engine.get_day_to_hide(function(data) {
            if (data.code == 0) {
                daysToHide.value = data.data.data[0].fieldData.Value.split(',').map((val) => parseInt(val))
            }
        })
    }

    const updateCalendarColors = function() {
        engine.get_calendar_colors(function(data) {
            if (data.code == 0 && data.data.data[0].fieldData.Value != "" && data.data.data[0].fieldData.Value != undefined) {
                eventColorById.value = {...eventColorById.value, ...JSON.parse(data.data.data[0].fieldData.Value) }
                refreshData()
            }
        })
    }

    const showErrorMessage = function(errorMessageValue, errorMessageValueLine2) {
        errorMessage.value = [errorMessageValue, errorMessageValueLine2];
        setTimeout(function() {
            errorMessage.value = undefined;
        }, 5000);
    };

    const initDraggbleEvents = function() {
        var containerEl = document.getElementById("external-events");
        var calendarEl = document.getElementById("calendar");
        new Draggable(containerEl, {
            itemSelector: ".fc-event",
            eventData: function(eventEl) {
                return {
                    title: eventEl.innerText,
                };
            },
        });

        new Calendar(calendarEl, {
            plugins: [dayGridPlugin, interactionPlugin, resourceTimelinePlugin],
            initialView: "resourceTimelineDay",
        });
    };
    const initEvents = function() {

        engine.get_events_liste(
            moment(view.value.start).format('MM/DD/YYYY'),
            moment(view.value.end).add(1, 'days').format('MM/DD/YYYY'),
            function(data) {
                isDataLoaded.value = true
                if (data.code == '0') {
                    let list = data.data.data
                    let tmpEventsList = []
                    let tmpEventsCalendar = []
                    for (let i = 0; i < list.length; i++) {
                        let technicienTab = [
                            list[i].fieldData['Techniciens_Nom(1)'],
                            list[i].fieldData['Techniciens_Nom(2)'],
                            list[i].fieldData['Techniciens_Nom(3)'],
                            list[i].fieldData['Techniciens_Nom(4)'],
                            list[i].fieldData['Techniciens_Nom(5)']
                        ].filter((val) => val != null && val != "")
                        technicienTab = technicienTab.filter(function(ele, pos) {
                            return technicienTab.indexOf(ele) == pos;
                        })
                        let event = {
                            id: list[i].fieldData.id,
                            adliv: list[i].fieldData.adliv,
                            'N° RAP COMP': list[i].fieldData['N° RAP COMP'],
                            'Theme devis': list[i].fieldData['Theme devis'],
                            '_LINK MOTEUR': list[i].fieldData['_LINK MOTEUR'],
                            'Statut': list[i].fieldData['Statut'],
                            'societeliv': list[i].fieldData['societeliv'],
                            'villeliv': list[i].fieldData['villeliv'],
                            'Telephone': list[i].fieldData['Telephone'],
                            'Debut': moment(list[i].fieldData['Debut']).format(),
                            'Fin': moment(list[i].fieldData['Fin']).format(),
                            'Technicien': technicienTab,
                            'compadliv': list[i].fieldData['compadliv'],
                            'TYPE MACHINE': list[i].fieldData['TYPE MACHINE'],
                            'N° serie constr.': list[i].fieldData['N° serie constr.'],
                            'MARQUE MACHINE': list[i].fieldData['MARQUE MACHINE'],
                            'N° MATR INT': list[i].fieldData['N° MATR INT'],
                            'ID_Agence': list[i].fieldData['ID_Agence'],
                            'Message_Interne': list[i].fieldData['Message_Interne'],
                        }
                        if (list[i].fieldData['Statut'] !== '140') {
                            if (list[i].fieldData['Statut'] == '100' || list[i].fieldData['Statut'] == undefined || (list[i].fieldData['Debut'] == '' || list[i].fieldData['Fin'] == '')) {
                                tmpEventsList.push(event)
                            } else if (list[i].fieldData['Statut'] != '100') {
                                let title = ""
                                    // title = list[i].fieldData['N° RAP COMP'] + ' ' + list[i].fieldData['villeliv'] + ' ' + list[i].fieldData['adliv'] + ' ' + list[i].fieldData['Theme devis']
                                if (list[i].fieldData['societeliv']) title += list[i].fieldData['societeliv'] + ' : '
                                if (list[i].fieldData['Theme devis']) title += list[i].fieldData['Theme devis']
                                tmpEventsCalendar.push({
                                    title: (fullView.value == false ? technicienTab + ' - ' : '') + title,
                                    resourceIds: technicienTab,
                                    start: moment(list[i].fieldData['Debut']).format(),
                                    end: moment(list[i].fieldData['Fin']).format(),
                                    event: event,
                                    color: eventColorById.value[list[i].fieldData['Statut']].color,
                                })
                            }
                        }
                    }
                    eventsList.value = tmpEventsList
                    eventsCalendar.value = tmpEventsCalendar
                }
            })
        initOldEvents()
    };

    const initOldEvents = function() {
        engine.get_old_events_liste(
            moment(view.value.start).format('MM/DD/YYYY'),
            moment(view.value.end).format('MM/DD/YYYY'),
            function(data) {
                isDataLoaded.value = true
                if (data.code == '0') {
                    let list = data.data.data
                    let tmpEventsCalendarOld = []
                    for (let i = 0; i < list.length; i++) {
                        let start = undefined
                        let end = undefined

                        if (list[i].fieldData['DATE RAPPORT'] && list[i].fieldData['DATE RAPPORT'].length != 0) {
                            start = list[i].fieldData['DATE RAPPORT'] + ' ' + list[i].fieldData['Heure Arriv(1)']
                            end = list[i].fieldData['DATE RAPPORT'] + ' ' + list[i].fieldData['Heure dep(1)']
                        } else {
                            start = list[i].fieldData['Debut']
                            end = list[i].fieldData['Fin']
                        }

                        let event = {
                            id: list[i].fieldData.id,
                            adliv: list[i].fieldData.adliv,
                            'N° RAP COMP': list[i].fieldData['N° RAP COMP'],
                            'Theme devis': list[i].fieldData['Theme devis'],
                            '_LINK MOTEUR': list[i].fieldData['_LINK MOTEUR'],
                            'Statut': '140',
                            'societeliv': list[i].fieldData['societeliv'],
                            'villeliv': list[i].fieldData['villeliv'],
                            'Telephone': list[i].fieldData['Telephone'],
                            'Debut': start,
                            'Fin': end,
                            'Technicien': list[i].fieldData['Techniciens_Nom(1)'],
                            'compadliv': list[i].fieldData['compadliv'],
                            'TYPE MACHINE': list[i].fieldData['TYPE MACHINE'],
                            'N° serie constr.': list[i].fieldData['N° serie constr.'],
                            'MARQUE MACHINE': list[i].fieldData['MARQUE MACHINE'],
                            'N° MATR INT': list[i].fieldData['N° MATR INT'],
                            'ID_Agence': list[i].fieldData['ID_Agence'],
                            'Message_Interne': list[i].fieldData['Message_Interne'],
                        }
                        let title = ""
                        if (list[i].fieldData['societeliv']) title += list[i].fieldData['societeliv'] + ' : '
                        if (list[i].fieldData['Theme devis']) title += list[i].fieldData['Theme devis']

                        tmpEventsCalendarOld.push({
                            resourceIds: [list[i].fieldData['Techniciens_Nom(1)']],
                            title: (fullView.value == false ? list[i].fieldData['Techniciens_Nom(1)'] + ' - ' : '') + title,
                            start: moment(start).format(),
                            end: moment(end).format(),
                            event: event,
                            color: eventColorById.value[140].color,
                        })
                    }
                    eventsCalendarOld.value = tmpEventsCalendarOld
                }
            })
    };

    const refreshData = function() {
        updateCalendarView()
        setTechnicienAvailable()
    }

    const updateCalendarView = function(calendarView) {
        if (calendarView == undefined) {
            initEvents()
        }
        if (calendarView != undefined && (view.value.start !== calendarView.startStr || view.value.end !== calendarView.endStr)) {
            view.value.start = calendarView.startStr
            view.value.end = calendarView.endStr
            initEvents()
        }
        goToSpecialDay()
    }

    const goToSpecialDay = function() {
        let day = moment(calendarDate.value).format('ddd')
        let elem = document.getElementsByClassName("fc-day-" + day.toLocaleLowerCase());
        if (elem[0]) elem[0].scrollIntoView({ inline: "center" });
    }

    const isTechnicienAvailable = function(e, event, techList) {
        let techNotAvailable = [];
        for (let a = 0; a < techList.length; a++) {
            let technicienListAvailableSelected =
                technicienListAvailable.value.filter(
                    (val) => val.TECHNICIEN === techList[a]
                );
            for (let i = 0; i < technicienListAvailableSelected.length; i++) {
                if (
                    moment(e.event.start).isBetween(
                        moment(technicienListAvailableSelected[i].start),
                        moment(technicienListAvailableSelected[i].end)
                    ) ||
                    moment(e.event.end).isBetween(
                        moment(technicienListAvailableSelected[i].start),
                        moment(technicienListAvailableSelected[i].end)
                    )
                ) {
                    techNotAvailable.push(techList[a]);
                }
            }
        }
        if (techNotAvailable.length != 0) {
            showErrorMessage(
                "ERREUR : Intervention " +
                selectedEvent.value["N° RAP COMP"] +
                " technicien(s) non disponible : " + techNotAvailable.join(' - ') + ""
            );
            e.revert();
            return false;
        }
        return true;
    };

    const getPlanningTechSettings = function() {
        engine.get_calendar_settings(function(data) {
            if (data.code == 0) {
                let planningSettingsTab = data.data.data.map((val) => ({ 'Name': val.fieldData.Name, 'Params': val.fieldData.Params == 1 ? true : false }))
                for (let i = 0; i < planningSettingsTab.length; i++) {
                    planningSettings.value[planningSettingsTab[i].Name] = planningSettingsTab[i].Params
                }
            }
        })
    }

    const arrayAreTheSame = function(arr1, arr2) {
        if (arr1.length != arr2.length)
            return false
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] != arr2[i])
                return false
        }
        return true
    }

    const editEventFromCalendar = function(newEvent, tech, refresh) {
        let eventFileMakerFormat = {
            // 'Statut': selectedEvent.value.Statut <= 130 ? 110 : selectedEvent.value.Statut,
            'Statut': selectedEvent.value.Statut == 100 ? 110 : selectedEvent.value.Statut,
            'Debut': newEvent.event.startStr,
            'Fin': newEvent.event.endStr,
        }
        if (tech !== undefined) {
            if (isTechnicienAvailable(newEvent, false, tech)) {
                if (newEvent.oldEvent == undefined || !arrayAreTheSame(newEvent.oldEvent._def.resourceIds, tech))
                    eventFileMakerFormat['Statut'] = "110"
                else {
                    eventFileMakerFormat['Statut'] = newEvent.oldEvent._def.extendedProps.event.Statut
                }
                for (let i = 0; i < 5; i++) {
                    let key = 'Techniciens_Nom(' + (i + 1) + ')'
                    eventFileMakerFormat[key] = tech[i] == undefined ? "" : tech[i]
                }
            } else {
                return false
            }
        }
        if (eventFileMakerFormat.Fin === '')
            eventFileMakerFormat.Fin = moment(eventFileMakerFormat.Debut).add(2, 'h').format()

        eventFileMakerFormat.Debut = moment(eventFileMakerFormat.Debut).format('MM/DD/YYYY HH:mm:ss')
        eventFileMakerFormat.Fin = moment(eventFileMakerFormat.Fin).format('MM/DD/YYYY HH:mm:ss')

        engine.set_event(selectedEvent.value.id, eventFileMakerFormat, function() {
            if (refresh === true) {
                refreshData()
                calendarDate.value = moment(newEvent.event.start).format('YYYY-MM-DD')
            }
        })
    }

    const getFilteredEvents = computed(function() {
        let liste = commentaireListe.value.concat(technicienListAvailable.value.concat(eventsCalendarOld.value.concat(eventsCalendar.value)))
        return liste
    })

    const getFilteredTechnicien = computed(function() {
        let techList = technicienList.value.filter((val) => selectedTechniciens.value.includes(val.id))
        if (selectedAgency.value == undefined)
            return techList
        else {
            return techList.filter((val) => val.ID_Agence == selectedAgency.value)
        }
    })

    const initTechnicienList = function() {
        engine.get_technicien_liste(function(data) {
            if (data.code == 0) {
                technicienList.value = data.data.data.map((val) => ({ id: val.fieldData.NOM_TECHNICIEN, title: val.fieldData.NOM_TECHNICIEN, ID_Agence: val.fieldData.ID_Agence }))
                getTechToShow()
            }
            engine.get_agency_soft(function(data) {
                if (data.code === 0) {
                    agencyList.value = data.data.data.map((val) => ({
                        text: val.fieldData.ag_nom,
                        id: val.fieldData.ID_Agence,
                    }));
                }
                refreshData()
            });

        });
    }

    watch(selectedAgency, (val) => {
        setAgency(val)
    });

    const setCommentaires = function() {
        engine.get_commentaires_liste(function(data) {
            if (data.code == 0) {
                let dataFiltered = data.data.data.filter((val) => val.fieldData.state == 1)
                commentaireListe.value = dataFiltered.map((val) => ({
                    "Clé primaire": val.fieldData["Clé primaire"],
                    DEBUT: val.fieldData["DEBUT"],
                    FIN: val.fieldData["FIN"],
                    start: moment(val.fieldData["DEBUT"]).format(),
                    end: moment(val.fieldData["FIN"]).format(),
                    TECHNICIEN: val.fieldData["TECHNICIEN"],
                    resourceIds: [val.fieldData["TECHNICIEN"]],
                    display: 'background',
                    title: val.fieldData["COMMENTAIRE"],
                    backgroundColor: 'blue',
                }));
            }
        });
    };


    const setTechnicienAvailable = function() {
        engine.get_technicien_available_liste(function(data) {
            if (data.code == 0) {
                let dataFiltered = data.data.data.filter((val) => val.fieldData.state == 1)
                technicienListAvailable.value = dataFiltered.map((val) => ({
                    resourceIds: [val.fieldData["TECHNICIEN"]],
                    "Clé primaire": val.fieldData["Clé primaire"],
                    DEBUT: val.fieldData["DEBUT"],
                    FIN: val.fieldData["FIN"],
                    start: moment(val.fieldData["DEBUT"]).format(),
                    end: moment(val.fieldData["FIN"]).format(),
                    TYPE: val.fieldData["TYPE"],
                    TECHNICIEN: val.fieldData["TECHNICIEN"],
                    display: 'background',
                    title: val.fieldData["TYPE"],
                    backgroundColor: 'red',
                }));
            }
        });
    };

    const isRoot = computed(function() {
        console.log("user", store.state.user);
        return store.state.user.admin == 1 ? true : false
    })

    onMounted(() => {
        updateCalendarColors()
        updateCalendarDaysToShow()
        initDraggbleEvents()
        initTechnicienList()
        getPlanningTechSettings()
        setTechnicienAvailable()
        setCommentaires()
        getAgency()
        selectedTechniciens.value = store.state.user.tech_to_show_planning_technicien.split(',')

    })

    return {
        eventsList,
        calendarOptions,
        selectedEvent,
        isModalEventOpen,
        eventsCalendar,
        editEventFromCalendar,
        isDataLoaded,
        eventColorById,
        initEvents,
        calendarDate,
        refreshData,
        isConnected,
        selectedAgency,
        agencyList,
        technicienList,
        selectedTechniciens,
        technicienListAvailable,
        setTechnicienAvailable,
        isTechnicienAvailable,
        showErrorMessage,
        errorMessage,
        planningSettings,
        isRoot,
        updateCalendarColors,
        updateCalendarDaysToShow,
        daysToHide,
        isModalSettingsOpen,
        commentaireListe,
        setCommentaires,
    };
}